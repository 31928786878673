import { cloneDeep, compact, filter, find, forEach, get, includes, isNil, keys, map } from 'lodash';

import { rxVariable } from '@core/models/Content';

import { States } from '../enums';
import { AI_PROMPT as TIMELINE_AI_PROMPT_TEXT } from './TimelineEvent';
import { EXTERNAL_TYPES, ValueType, VariableType } from './Variable';

export const DEMANDS_AI_PROMPT = {
  system: `You are an experienced personal injury attorney who is an expert in writing demand letters for law firms. Specifically, you are a specialized drafting consultant for law firms. You are given an example or examples of a law firm's customary language for a section of their demand letters from their past cases. Then, you are given details of a new case, and you use the style, format, tone, voice, purpose, structure, word choice, and types of information included in the examples to draft the corresponding section for a demand letter reflecting the details of the new case. The sections that you draft apply the style, format, tone, voice, purpose, structure, word choice, and types of information included in the examples but use the details of the new case to create an effective section of the demand letter for the law firm.
  When you draft a section, the section should emulate the style, format, tone, voice, purpose, structure, word choice, and types of information included in the example sections, which are placed in the <example> </example> XML tags.
  If an item of case details begins with "[+fv", consider that entry as indicating that there is no information for that item, and that such item should be omitted from the section and not considered during the drafting of the section. "[+fv" is appearing because there was nothing available to be pulled from the data set for that item of case details.
  Read the details and the examples carefully before drafting the section because the section you draft will reflect the style, format, tone, voice, purpose, structure, and word choice of the examples and the facts of the details.
  Only include the details in your draft which are relevant for the particular section being drafted, which you determine as relevant through a comprehensive review of the examples and the types of information which they discuss. If a type of information is included with the details, but the examples do not reference or discuss that type of information, do NOT include it in your section.`,
  user: `Carefully and thoroughly review the details placed in the <details></details> XML tags and the style, format, tone, voice, purpose, structure, word choice, and types of information included in the examples placed in the <example></example> XML tags.
  Referencing the details placed in the <details></details> XML tags and the style, format, tone, voice, purpose, structure, word choice, and types of information included in the examples placed in the <example></example> XML tags, draft the corresponding section of the demand letter.
  Think step by step within <thinking></thinking> XML tags. Then, draft the section within <draft></draft> XML tags.
  For the factual details of the incident, use ONLY the details placed in the <details></details> XML tags, and do not use any of the facts from the examples. Refer to the examples ONLY for tailoring the style, format, tone, voice, purpose, structure, word choice, and types of information of the section and NOT for the facts, details, or information of the incident.`,
};

export const AI_PROMPTS = [
  { name: 'Clause Extraction', project_id: 'pr_jM77mOzrWn7QKKyLmtTQ8' },
  { name: 'Has Additional Terms', project_id: 'pr_LewgWQXeqSTsyiyUHbLLN' },
  { name: 'Missing Preferred Language', project_id: 'pr_iAAsHWEXk7XG1mTtHxN1s' },
  { name: 'Redlining', project_id: 'pr_YtKE9o3BZ0tSeLT6sSqWI' },
  { name: 'Variable Extraction', project_id: 'pr_hz85ssy3FGV97Cmeymj97' },
  { name: 'Open Prompt Lenses', project_id: 'pr_Hh8dTxyD5IR3qeSuoCh5s' },
];

export const AI_VARIABLE_FORMATS = [
  { type: ValueType.DATE, format: () => 'Format your response as MM/DD/YYYY.' },
  {
    type: ValueType.STATE,
    format: () => `Your response must be one of the following options: ${States.join(', ')}`,
  },
  {
    type: ValueType.NUMBER,
    format: () => 'Your value must be a valid number.',
  },
  {
    type: ValueType.PERCENT,
    format: () => 'Your value must be a valid number.',
  },
  {
    type: ValueType.CURRENCY,
    format: () => 'Your value must be a valid number.',
  },
];

export const AI_ENGINES = [
  {
    title: 'Vinnie AI',
    key: 'vinnie',
    model: 'gpt-4o',
  },
  {
    title: 'Open AI',
    key: 'gpt_4o',
    description: '',
    model: 'gpt-4o',
    systemPrompt: true, // TODO: CJA: We are cuurently not using 'systemPrompt' value anywhere, should kill this?
    max_tokens: 8191, // TODO: CJA: we are currently not using this "max_tokens" value for OpenAI models, and perhaps they ought not to be specified here?
  },
  {
    title: 'Open AI',
    key: 'openAI_gpt_4o_64k_output_alpha',
    description: '',
    model: 'gpt-4o-64k-output-alpha',
    systemPrompt: true,
  },
  {
    title: 'Open AI',
    key: 'openAI_gpt_4',
    description: '',
    model: 'gpt-4',
    systemPrompt: true,
    max_tokens: 8191,
  },
  {
    title: 'Anthropic',
    key: 'anthropic',
    description: '',
    model: 'claude-2.1',
    systemPrompt: true,
    max_tokens: 4096,
  },
  {
    title: 'Anthropic',
    key: 'anthropic_opus',
    description: '',
    model: 'claude-3-opus-20240229',
    systemPrompt: true,
    max_tokens: 4096,
  },
  {
    title: 'Anthropic',
    key: 'anthropic_sonnet',
    description: '',
    model: 'claude-3-sonnet-20240229',
    systemPrompt: true,
    max_tokens: 4096,
  },
];

export const STEP_TYPES = {
  SYSTEM: 'system',
  USER: 'user',
  ASSISTANT: 'assistant',
  OUTLAW_USER: 'outlaw',
};

export const RESPONSE_TYPES = [
  {
    key: 'block',
    title: 'Self - block',
    description: 'Single paragraph that should populate the body of this AI Block',
  },
  {
    key: 'list',
    title: 'Self - list',
    description: 'Multiple paragraphs that should populate the children of this AI Block as separate sections',
  },
  {
    key: 'variable',
    title: 'Variable',
    description: 'Data that should populate a target Variable',
  },
];

export const DATA_SOURCE_TYPES = {
  SECTIONS: 'Sections',
  VARIABLES: 'Variables',
};

const TIMELINE_PROMPT = [
  { role: STEP_TYPES.SYSTEM, content: TIMELINE_AI_PROMPT_TEXT },
  { role: STEP_TYPES.USER, content: '' },
  { role: STEP_TYPES.OUTLAW_USER, content: '' },
];

const DEMANDS_PROMPT = [
  { role: STEP_TYPES.SYSTEM, content: DEMANDS_AI_PROMPT[STEP_TYPES.SYSTEM] },
  { role: STEP_TYPES.USER, content: DEMANDS_AI_PROMPT[STEP_TYPES.USER] },
  { role: STEP_TYPES.OUTLAW_USER, content: '' },
];

const DEFAULT_PROMPT = [
  { role: STEP_TYPES.SYSTEM, content: '' },
  { role: STEP_TYPES.USER, content: '' },
  { role: STEP_TYPES.OUTLAW_USER, content: '' },
];

// Default thread configuration options, stored as metadata on the thread
// Note all values must be strings
// https://platform.openai.com/docs/api-reference/threads/createThread
export const DEFAULT_VINNIE_OPTIONS = {
  examples: 'false',
  designations: 'true',
};

//Will be pulled from DB along with the structure for v2.
export const BLOCK_TYPE = {
  DEFAULT: 'default',
  DEMANDS: 'demands',
  TIMELINE: 'timeline',
};

export const BLOCK_PROMPT = {
  default: DEFAULT_PROMPT,
  demands: DEMANDS_PROMPT,
  timeline: TIMELINE_PROMPT,
};

export const BLOCK_PROMPT_LABEL = {
  default: 'None (default)',
  demands: 'Demands AI',
  timeline: 'Timeline',
};

export const RESPONSE_OPTIONS = [1, 2, 3];

// For use with Vinnie. These will hopefully soon replace ALL the prompt config,
// as Vinnie is pre-configured with detailed instructions on how to generate each section (including firm-specific examples)
// We'll probably need to add a few additional section types as we onboard customers with more specific needs,
// but the prompts themselves are dynamic (updated in Vinnie's knowledge)
// so this way we can keep the full list of supported section types below explicit and hard-coded, but update the prompts themselves without code changes
export const PROMPT_TYPES = [
  {
    title: 'Introduction',
    key: 'INTRO',
    summary: "Overview of the letter's purpose and demand.",
  },
  {
    title: 'Statement of Facts',
    key: 'FACTS',
    summary: 'Detailed background information and events.',
  },
  {
    title: 'Liability',
    key: 'LIABILITY',
    summary: 'Legal basis for liability.',
  },
  {
    title: 'Demand',
    key: 'DEMAND',
    summary: 'Specific actions or payments being demanded.',
  },
  {
    title: 'Economic Damages',
    key: 'ECON_DMG',
    summary: 'Financial losses incurred.',
  },
  {
    title: 'Non-Economic Damages',
    key: 'NON_ECON_DMG',
    summary: 'Non-monetary losses such as pain and suffering.',
  },
  {
    title: 'Medical/Injury Chronology',
    key: 'MED_CHRON',
    summary: 'Timeline of medical treatments and injuries.',
  },
  {
    title: 'Deadline',
    key: 'DEADLINE',
    summary: 'Specific time frame for compliance.',
  },
  {
    title: 'Consequences of Non-Compliance',
    key: 'CONSEQUENCES',
    summary: 'Potential legal or practical consequences of non-compliance.',
  },
  {
    title: 'Contact Information',
    key: 'CONTACT',
    summary: "Sender's contact details for further communication.",
  },
  {
    title: 'Summary of Damages',
    key: 'SUMMARY',
    summary: 'Summarizes total economic and non-economic damages.',
  },
  {
    title: 'Closing',
    key: 'CLOSING',
    summary: 'Formal conclusion expressing hope for resolution.',
  },
  {
    title: 'Misc. Damages',
    key: 'MISC_DAMAGES',
    summary: 'Additional financial or non-financial losses not categorized elsewhere.',
  },
  {
    title: 'Injuries',
    key: 'INJURIES',
    summary: 'Description of physical or emotional injuries sustained.',
  },
  {
    title: 'Lost Wages',
    key: 'LOST_WAGES',
    summary: 'Compensation for income lost due to inability to work.',
  },
  {
    title: 'Background',
    key: 'BACKGROUND',
    summary: 'Contextual information related to the parties and the incident.',
  },
  {
    title: 'Loss of Function',
    key: 'LOSS_OF_FUNCTION',
    summary: 'Impact on physical or mental capabilities resulting from the incident.',
  },
  {
    title: 'Future Medical',
    key: 'FUTURE_MEDICAL',
    summary: 'Anticipated medical treatments and costs in the future.',
  },
  {
    title: 'Expert Reports',
    key: 'EXPERT_REPORTS',
    summary: 'Professional assessments and conclusions from industry experts.',
  },
  {
    title: 'Verdict Analysis',
    key: 'VERDICT_ANALYSIS',
    summary: 'Evaluation of potential outcomes based on similar cases.',
  },
  {
    title: 'Enclosures',
    key: 'ENCLOSURES',
    summary: 'List of accompanying documents and evidence.',
  },
  {
    title: 'Proceedings History',
    key: 'PROCEEDINGS_HISTORY',
    summary: 'Record of legal actions and decisions related to the case.',
  },
];

// CJA: Since we are bringing back the OUTLAW_USER step "for now", we should also bring this
// back so the custom instructions don't get wiped out:
export const togglePromptType = (type, currentPrompt) => {
  const newPrompt = [...BLOCK_PROMPT[type]];

  // Maintain custom instructions:
  const oldOutlaw = find(currentPrompt, { role: STEP_TYPES.OUTLAW_USER });
  if (oldOutlaw) {
    const newOutlaw = find(newPrompt, { role: STEP_TYPES.OUTLAW_USER });
    if (newOutlaw) {
      newOutlaw.content = oldOutlaw.content;
    }
  }

  return newPrompt;
};

// Moved this to be a reusable function outside of the AIPrompt instance
// this way we can use it both for individual sections with Variables source,
// and for an entire deal dump (Vinnie startThread)
export const buildVariableDump = (vars) => {
  const varValues = [];
  forEach(vars, (v) => {
    let promptText = `${v.displayName || v.name}: `;
    let val = v.val;
    const emptyValText = `[${v.type}${v.name}]`;
    if (v.valueType === ValueType.TABLE) {
      if (get(v, 'val.length') > 0) {
        promptText += '\n';
        promptText += JSON.stringify(val);
      } else {
        promptText += emptyValText;
      }
    } else {
      if (v.val) promptText += v.val;
      else promptText += emptyValText;
    }

    varValues.push(promptText);
  });
  return varValues.join('\n\n');
};

export const buildAssistantVariableDump = (vars) => {
  const varValues = [];
  const medicalRecordsByProvider = [];
  const collectionItems = [];
  forEach(vars, (v) => {
    //Split fv connected collections by items and medical records by provider
    if (v.type === VariableType.CONNECTED && v.externalType === EXTERNAL_TYPES.COLLECTION && v.val) {
      const hasProviders = includes(keys(v.val[0]), 'provider');
      const providers = hasProviders ? map(v.val, 'provider') : [];
      if (providers.length > 0) {
        //If we have providers (large med records) split them by provider if its a active field.
        //This is a known field for many large clients (we can't rely on it though because collections are completely custom to the user)
        forEach(providers, (provider) => {
          const providerMedChron = filter(v.val, { provider });
          medicalRecordsByProvider.push({ provider, providerMedChron: JSON.stringify(providerMedChron) });
        });
      } else {
        //Split all other collection items into text files. We need to do this to account for med records that are structured differently.
        forEach(v.val, (item) => {
          collectionItems.push({
            name: v.externalSelector,
            collectionItemContent: JSON.stringify(item),
          });
        });
      }
    } else {
      let promptText = `${v.displayName || v.name}: `;
      let val = v.val;
      const emptyValText = `[${v.type}${v.name}]`;
      if (v.valueType === ValueType.TABLE) {
        if (get(v, 'val.length') > 0) {
          promptText += '\n';
          promptText += JSON.stringify(val);
        } else {
          promptText += emptyValText;
        }
      } else {
        if (v.val) promptText += v.val;
        else promptText += emptyValText;
      }
      varValues.push(promptText);
    }
  });

  const totalMessages = collectionItems.length + medicalRecordsByProvider.length + 1;

  return { varValues: varValues.join('\n\n'), medicalRecordsByProvider, collectionItems, totalMessages };
};

//V2
//Outlaw admins will build prompts and save them at a universal level in outlaw. (They will enable prompts based on some criteria)
//Preconfiged Prompts will be selected on the template/deal for use. (Always hidden though)
//

export default class AIPrompt {
  engine = null;
  prompt = [];
  actionName = '';
  linkedSections = [];
  linkedVariables = '';
  type = null;
  responseOptions = 1;
  temperature = 1;
  responseType = 'block';
  outputVariable = null;
  dsType = DATA_SOURCE_TYPES.SECTIONS;
  autorun = false;
  lastResponse = null;
  // Normally we'd track this in an unstored component state var,
  // but because we have multiple sections with AIPrompts potentially auto-running in sequence,
  // and because a lengthy/complex prompt can take a while to complete,
  // it's safest to actually just store the running state in db while active
  isRunning = false;
  lastError = null;

  // Whether response should be streamed. Currently only supported with Vinnie
  streaming = false;
  // For Vinnie, key of one of the PROMPT_TYPES values for preset prompts
  promptTypeKey = null;

  // ES: moved to static class method to follow factory pattern
  // and removed "legacy" from name since this is actually our standard (current) only path to instantiate new AIPrompts
  static buildJSON(section, workflow, type) {
    const aiPrompt = {};
    aiPrompt.actionName = get(section, 'actionName', 'Generate');
    aiPrompt.responseOptions = 1;

    if (workflow) {
      //This means its a demand setup
      aiPrompt.prompt = workflow.serviceProviders ? cloneDeep(DEMANDS_PROMPT) : cloneDeep(DEFAULT_PROMPT);
      aiPrompt.engine = workflow.aiEngine || AI_ENGINES[0].key;
      aiPrompt.type = workflow.serviceProviders ? BLOCK_TYPE.DEMANDS : BLOCK_TYPE.DEFAULT;
    } else {
      aiPrompt.prompt = cloneDeep(DEFAULT_PROMPT);
      aiPrompt.engine = AI_ENGINES[0].key;
      aiPrompt.type = BLOCK_TYPE.DEFAULT;
    }

    //TODO: this is where we'll extend the logic and Workflow models
    //to allow admins to define Workflow-specific AIPrompt templates and apply them to blocks
    //right now we're leaving the above logic to auto-discover type from the Workflow
    //but these will be merged
    if (type) {
      const promptTemplate = BLOCK_PROMPT[type];
      if (promptTemplate) {
        aiPrompt.prompt = cloneDeep(promptTemplate);
        aiPrompt.type = type;
      }
    }

    // Legacy blocks had a simple text field for the AI prompt on the Section
    // if we find that, map it to the OUTLAW_USER step, which is user editable.
    if (section.prompt) {
      const outlawPrompt = find(aiPrompt, { role: STEP_TYPES.OUTLAW_USER });
      if (outlawPrompt) {
        outlawPrompt.content = section.prompt;
      }
    }

    // Legacy prompts used section linking to target source data
    // translate these if we find them
    if (section.children?.length > 0) {
      const linkedIDs = map(section.children, 'id');
      aiPrompt.linkedSections = linkedIDs.join('|');
    }

    return aiPrompt;
  }

  constructor(json, deal) {
    this.deal = deal;

    this.actionName = get(json, 'actionName', 'Generate');
    this.engine = find(AI_ENGINES, { key: json.engine }) || AI_ENGINES[0];
    this.prompt = get(json, 'prompt', DEFAULT_PROMPT);
    this.linkedSections = json.linkedSections ? json.linkedSections.split('|') : [];
    this.linkedVariables = get(json, 'linkedVariables', '');
    this.type = get(json, 'type', BLOCK_TYPE.DEFAULT);
    this.responseOptions = get(json, 'responseOptions', 1);
    this.temperature = get(json, 'temperature', 1);
    this.responseType = get(json, 'responseType', 'block');
    this.outputVariable = get(json, 'outputVariable') || null;
    this.dsType = get(json, 'dsType', DATA_SOURCE_TYPES.SECTIONS);
    this.autorun = get(json, 'autorun', false);
    this.lastResponse = get(json, 'lastResponse', null);
    this.isRunning = get(json, 'isRunning', false);
    this.streaming = get(json, 'streaming', false);
    this.promptTypeKey = get(json, 'promptTypeKey', null);

    this.lastError = get(json, 'lastError', null);
  }

  get json() {
    return {
      engine: this.engine.key,
      actionName: this.actionName,
      prompt: this.prompt,
      linkedSections: this.linkedSections.length > 0 ? this.linkedSections.join('|') : null,
      linkedVariables: this.linkedVariables || null,
      type: this.type,
      responseOptions: this.responseOptions,
      responseType: this.responseType,
      temperature: this.temperature,
      outputVariable: this.outputVariable || null,
      dsType: this.dsType,
      autorun: this.autorun || null,
      lastResponse: isNil(this.lastResponse) ? null : this.lastResponse,
      isRunning: this.isRunning ? true : null,
      streaming: this.streaming ? true : null,
      promptTypeKey: this.promptTypeKey || null,
      lastError: isNil(this.lastError) ? null : this.lastError,
    };
  }

  get dataSourceVariables() {
    //find variable ranges and add them as styles for flattened rendering
    let variables = [],
      matchVar;

    while ((matchVar = rxVariable.exec(this.linkedVariables)) !== null) {
      const varName = matchVar[0].slice(2, matchVar[0].length - 1).split('.')[0];
      const v = this.deal.variables[varName];
      if (v) variables.push(v);
    }

    return variables;
  }

  get dataSourceAI() {
    switch (this.dsType) {
      case DATA_SOURCE_TYPES.VARIABLES:
        return buildVariableDump(this.dataSourceVariables);
      case DATA_SOURCE_TYPES.SECTIONS:
      default:
        let table = null,
          text = null,
          source = [];

        const children = compact(map(this.linkedSections, (id) => this.deal.sections[id]));

        find(children, (child) => {
          table = find(child.variables, (v) => {
            return v.valueType === ValueType.TABLE || v.externalType === EXTERNAL_TYPES.COLLECTION;
          });

          if (table) return true;
          return false;
        });

        if (table) {
          return table.val;
        } else {
          source = children;

          // If we're pointing at a LIST, use that list's children as the source content
          if (source.length > 0 && source[0].isList && !source[0].isAI) {
            source = source[0].items;
          }

          text = map(source, (sec) => sec.currentVersion.getText('body', true, this.deal.variables)).join('\n\n');
          return text.trim() || null;
        }
    }
  }

  // This uses the same logic as dataSourceAI() to get the underlying target sections,
  // but the text output is formatted specifically for Timeline generation,
  // which requires explicit Paragraph (Section) IDs to be included for "source" linking
  get timelineSourceAI() {
    let table = null,
      text = null,
      source = [];

    const children = map(this.linkedSections, (id) => this.deal.sections[id]);

    find(children, (child) => {
      table = find(child.variables, (v) => {
        return v.valueType === ValueType.TABLE || v.externalType === EXTERNAL_TYPES.COLLECTION;
      });

      if (table) return true;
      return false;
    });

    if (table) {
      return table.val;
    } else {
      source = children;

      // If we're pointing at a LIST, use that list's children as the source content
      if (source.length > 0 && source[0].isList && !source[0].isAI) {
        source = source[0].items;
      }

      text = map(source, (sec) => {
        let para = `Paragraph ID: ${sec.id}\n`;
        para += `Paragraph Text: \n`;
        para += sec.currentVersion.getText('body', true, this.deal.variables);
        return para;
      }).join('\n\n');

      return text.trim() || null;
    }
  }

  // TODO: this works, but results in chaotic behavior where multiple blocks are autorunning at once
  // we should move this out of the individual block level (and probably to the server side)
  // to ensure that only 1 AI Block can autorun at a time, even if several are ready to go
  // Also move the "loading" state variable to be saved/stored on the AIPrompt
  // so that the user can still watch the magic sequentially while the autorun is executing
  get canAutorun() {
    const { autorun, isRunning, lastResponse, dataSourceAI } = this;

    // If autorun is currently running or has already run, stop here
    if (!autorun || isRunning || lastResponse || !dataSourceAI) return false;

    // console.log(`[${this.actionName}]`, 'Passed initial autorun check');

    if (this.dsType === DATA_SOURCE_TYPES.SECTIONS) {
      const linkedSections = map(this.linkedSections, (id) => this.deal.sections[id]);
      const todo = find(linkedSections, (sec) => !sec || !sec.content || sec.todo > 0);
      if (todo) {
        // console.log(`[${this.actionName}]`, todo);
        // console.log(`[${name}]`, 'Autorun cancelled: missing vars in linked sections');
        return false;
      }
    } else if (this.dsType === DATA_SOURCE_TYPES.VARIABLES) {
      // TODO: check that all vars are present
    }

    // NO NAME/ID specific property available for AIPrompt...
    // console.log(`[${name}]`, 'Autorun running!');
    // console.log('I SHOULD AUTORUN');

    return true;
  }

  get isAssistant() {
    return this.json.engine === 'vinnie';
  }

  get isCompletion() {
    return this.json.engine !== 'vinnie';
  }

  get userPromptText() {
    return find(this.prompt, { role: STEP_TYPES.USER })?.content || '';
  }

  get outlawPromptText() {
    return find(this.prompt, { role: STEP_TYPES.OUTLAW_USER })?.content || '';
  }

  get systemPromptText() {
    return find(this.prompt, { role: STEP_TYPES.SYSTEM })?.content || '';
  }

  // The idea here is to start a parametric framework where we can fine-tune the language style based on these params
  // These settings are stored at the Team level in the AITeamConfig model. For now, the initial two parameters control:
  // - designations: whether to attempt to track existing designations, so as not to repeat them again in subsequent AI Blocks
  // - examples: whether Vinnie should look for (team-specific) example content in its knowledge and attempt to mimic it
  // - rules[]: if example training materials have been uploaded, there will be section-specific rules created
  buildVinniePrompt(aiConfig) {
    const { examples, designations, rules } = aiConfig;

    let messages = [];

    const instructions = [
      `Draft the following section according to the prompt instructions in your knowledge: [${this.promptTypeKey}]`,
    ];

    // If we have rules for this section type, insert them into the prompt here!
    let teamRules = filter(rules, { sectionType: this.promptTypeKey });
    if (examples && teamRules.length > 0) {
      instructions.push(
        `This firm has also provided the following drafting rules specific to this section.
If any of these rules conflict with the standard drafting instructions for this section from your knowledge, these rules should take precedence:

` + JSON.stringify(teamRules)
      );
    }

    if (designations) {
      instructions.push(
        `Keep track of all designations (pseudonyms listed inside parentheses) established in this thread for both the Plaintiff and Defendant, and do not repeat them once they have been established.`
      );
    }

    messages.push({
      role: 'user',
      content: instructions.join('\n\n'),
    });

    return messages;
  }

  buildAPIPrompt(source) {
    let messages = [];
    let system = find(this.prompt, { role: STEP_TYPES.SYSTEM })?.content || '';
    let user = find(this.prompt, { role: STEP_TYPES.USER })?.content || '';
    let outlaw = find(this.prompt, { role: STEP_TYPES.OUTLAW_USER })?.content || '';

    // Anthropic has a separate way to "inject" the system prompt, but for OpenAI
    // it gets sent as just part of the messages array.
    if (!this.engine.key.startsWith('anthropic')) {
      if (system) {
        messages.push({ role: STEP_TYPES.SYSTEM, content: system });
      }
    }

    if (outlaw) {
      if (user) {
        user += '\n\n';
      }
      user += outlaw;
    }

    if (source) {
      if (user) {
        user += '\n\n';
      }
      user += source;
    }

    if (user) {
      messages.push({ role: STEP_TYPES.USER, content: user });
    }

    return { system, messages };
  }
}
