import React, { Component } from 'react';

import autoBindMethods from 'class-autobind-decorator';
import cx from 'classnames';
import _ from 'lodash';
import PropTypes from 'prop-types';

import { ButtonGroup, FormControl, Modal } from 'react-bootstrap';
import MarkdownRenderer from 'react-markdown-renderer';

import { AI_PROMPTS } from '@core/models/AIPrompt';
import Deal from '@core/models/Deal';
import Team from '@core/models/Team';
import { DateFormatter } from '@core/utils/DateTime';

import { Alert, Button, ButtonIcon, Icon, Loader, Switch } from '@components/dmp';

import VariableView from '@components/VariableView';
import DealPanel, { DealPanelPropTypes } from '@components/deal/DealHeader/DealPanel';
import DealPanelItem from '@components/deal/DealHeader/DealPanelItem';
import TooltipButton from '@components/editor/TooltipButton';
import Fire from '@root/Fire';

import LensCheck from './deal/LensCheck';

const DOCUMENT_ANALYSIS_OPTIONS = [
  {
    key: 'ocr',
    title: 'OCR (included)',
    test: () => true,
    subtext: 'Document is scanned and indexed',
    isDefault: true,
  },
  {
    key: 'variable',
    title: 'Variable analysis',
    test: (deal) => deal.documentAI && !deal.extracted?.error,
    subtext: '(For structured form data) Finds key value pairs and stores them in variables panel',
    isDefault: false,
  },
  {
    key: 'clause',
    title: 'Clause analysis',
    test: (deal) => deal.documentAI && !deal.extracted?.error && deal.extracted?.sections,
    subtext: 'Extracts key clauses from documents and runs prompts, e.g, compare to original.',
    isDefault: false,
  },
  {
    key: 'scoring',
    title: 'Scoring',
    test: (deal) => deal.hasLenses,
    subtext: 'Calculates a score based on variable values and found clauses within a document',
    isDefault: false,
  },
];

//Active set true for releasable pieces of PDF ingestion (Lenses)
export const PDF_REVIEW_TABS = [
  {
    key: 'summary',
    title: 'Summary',
    active: () => true,
  },
  {
    key: 'lenses',
    title: 'Scorecard',
    active: (deal) => deal.hasLenses && deal.lensChecks,
  },
  {
    key: 'terms',
    title: 'Variables',
    active: (deal) => deal.documentAI && deal.extracted && !deal.extracted.error,
  },
  {
    key: 'clauses',
    title: 'Clauses',
    active: (deal) => deal.documentAI && deal.extracted && !deal.extracted.error && deal.extracted.sections,
  },
];

@autoBindMethods
export default class PDFReview extends Component {
  static propTypes = {
    deal: PropTypes.instanceOf(Deal).isRequired,
    dealTeam: PropTypes.instanceOf(Team).isRequired,
    ...DealPanelPropTypes,
  };

  constructor(props) {
    super(props);

    this.state = {
      reviewOptions: this.reviewOptions,
      tab: 'summary',
      selectedDefinitions: {},
      results: false,
      selectedClause: null,
      showIssues: false,
      showVariableIssues: false,
      loadingClauseExtraction: false,
      manuallyEditClauseExtraction: false,
      editingClause: null,
      editingVariableValue: null,
      hideNonScoring: false,
      showQuestions: false,
      preferredLanguageCheck: null,
      showExtractData: false,
    };
  }

  get showFailedSwitch() {
    const { deal } = this.props;
    const { extracted, lensChecks } = deal;
    let showFailedSwitch = false;

    if (extracted && extracted.sections) {
      _.forEach(extracted.sections, (clause) => {
        if (clause.vitalIDs) {
          const vitalIDs = clause.vitalIDs.split(',');
          _.forEach(vitalIDs, (id) => {
            const check = _.find(lensChecks.checks, ({ lens }) => {
              return lens.id === id;
            });
            if (check?.failedCheck) {
              showFailedSwitch = true;
            }
          });
        }
      });
    }
    return showFailedSwitch;
  }

  async saveManualUpdates() {
    const { deal } = this.props;
    const { selectedClause, editingClause } = this.state;

    try {
      await Fire.updateExtractedClause(deal.dealID, editingClause, selectedClause.name);
      if (selectedClause.lensID)
        await API.call('rerunLensClauseCheck', { dealID: deal.dealID, lensID: selectedClause.lensID });
    } catch (err) {
      console.error(`Failed to queue lens check for deal ${deal.dealID}`);
    }
    this.setState({ editingClause: null, manuallyEditClauseExtraction: false });
  }

  async rerunClauseExtraction() {
    const { deal } = this.props;
    const { selectedClause } = this.state;

    this.setState({ loadingClauseExtraction: true });
    const OCR = _.values(deal.currentOCR?.content).join('');

    try {
      const projectID = _.find(AI_PROMPTS, { name: 'Clause Extraction' }).project_id;
      const inputs = {
        clause_name: selectedClause.name,
        OCR,
      };
      const response = await API.call('humanloop', { projectID, inputs });
      const output = response.text;
      await Fire.updateExtractedClause(deal.dealID, output ? output : 'Not Found', selectedClause.name);
      if (selectedClause.lensID)
        await API.call('rerunLensClauseCheck', { dealID: deal.dealID, lensID: selectedClause.lensID });
    } catch (err) {
      console.log(err);
    }
    this.setState({ loadingClauseExtraction: false });
  }

  async runPreferedLanguageCheck() {
    const { deal } = this.props;
    const { selectedClause } = this.state;
    const { extracted } = deal;

    this.setState({ loadingClauseDocumentComparison: true, showExtractData: true });

    const standardClause = extracted.sections[selectedClause.name].standardClause;

    const OCR = _.values(deal.currentOCR?.content).join('');

    const projectID = _.find(AI_PROMPTS, { name: 'Missing Preferred Language' }).project_id;
    const inputs = {
      clause_name: selectedClause.name,
      OCR,
      standard_terms: standardClause,
    };

    const response = await API.call('humanloop', { projectID, inputs });
    const output = response.text;

    this.setState({ clauseDocumentComparison: output });
    this.setState({ loadingClauseDocumentComparison: false });
  }

  async runRedlining() {
    const { deal } = this.props;
    const { selectedClause } = this.state;
    const { extracted } = deal;

    this.setState({ loadingClauseRedlining: true, showExtractData: true });

    const standardClause = extracted.sections[selectedClause.name].standardClause;
    const extractedClause = extracted.sections[selectedClause.name].extractedClause;

    const projectID = _.find(AI_PROMPTS, { name: 'Redlining' }).project_id;
    const inputs = {
      clause_name: selectedClause.name,
      extracted_clause: extractedClause,
      standard_terms: standardClause,
    };
    const response = await API.call('humanloop', { projectID, inputs });

    const output = response.text;

    this.setState({ loadingClauseRedlining: false });
    this.setState({ clauseRedlines: output });
  }

  renderDefinition(variable, idx) {
    const { editingVariableValue } = this.state;
    const { deal, user } = this.props;
    const { needsReview, extractedValue, variableName } = variable;

    const v = deal.variables[variableName];
    //Two cases to check for
    //1: User reviewed and approved a empty extracted value.
    // This case is true if we have toggled off needsReview and both values are still null or undefined.
    //2: The user reviewed or editied the extracted value.
    //This is a simple value comaprison.
    const humanReviewed = !extractedValue && !v.value && !needsReview ? true : v.value !== extractedValue;

    if (needsReview) {
      return (
        <DealPanelItem borderBottom className="deal-panel-item-type-danger" active={true}>
          <div className="definition" key={idx}>
            <div className={cx('term-definition-title')}>
              <b>{variableName}</b>
            </div>
            <VariableView
              variable={v}
              section={deal.root}
              text={`[#${v.name}]`}
              markReview
              user={user}
              onSave={(variable) => Fire.markVariableReviewed(variable)}
              deal={deal}
            />
          </div>
        </DealPanelItem>
      );
    } else {
      return (
        <DealPanelItem borderBottom data-cy="variable-panel-item">
          <div className="definition" key={idx}>
            <div className={cx('term-definition-title')}>
              <b>{variableName}</b>
            </div>
            <div className="term-info-container">
              {editingVariableValue !== v.name && (
                <div className="term-value" data-cy="term-value">
                  {humanReviewed ? (
                    <TooltipButton tip="Human-reviewed">
                      <Icon name="fieldsEdit" size="default" />
                    </TooltipButton>
                  ) : (
                    <TooltipButton tip="AI extracted">
                      <Icon name="aiAuto" size="default" />
                    </TooltipButton>
                  )}
                  <a
                    onClick={() =>
                      this.setState({
                        editingVariableValue: v.name,
                      })
                    }
                  >
                    {humanReviewed ? (v.value ? `"${v.value}"` : '(empty)') : `"${extractedValue}"`}
                  </a>
                </div>
              )}
            </div>
            {editingVariableValue === v.name && this.editVariable(v, extractedValue)}
          </div>
        </DealPanelItem>
      );
    }
  }

  editVariable(variable, extractedValue) {
    const { deal, user } = this.props;

    return (
      <VariableView
        variable={variable}
        section={deal.root}
        text={`[#${variable.name}]`}
        editExtractedValue
        onCancel={() => this.setState({ editingVariableValue: null })}
        user={user}
        onSave={(variable) => {
          if (variable.value !== extractedValue) Fire.markVariableReviewed(variable);
          this.setState({ editingVariableValue: null });
        }}
        deal={deal}
      />
    );
  }

  renderClauses(clause, idx) {
    return (
      <DealPanelItem borderBottom key={idx} className="clause-summary-container" data-cy="clause-panel-item">
        <div
          className="clause-name"
          onClick={() =>
            this.setState({ selectedClause: { name: clause.clauseName, failedCheck: false, lensID: clause.lensID } })
          }
        >
          {clause.clauseName}
        </div>
      </DealPanelItem>
    );
  }

  renderComparisonModal() {
    const {
      clauseRedlines,
      loadingClauseRedlining,
      loadingClauseDocumentComparison,
      clauseDocumentComparison,
      showExtractData,
    } = this.state;

    const loading = loadingClauseRedlining || loadingClauseDocumentComparison;
    const content = clauseRedlines || clauseDocumentComparison;

    const title =
      loadingClauseRedlining || clauseRedlines
        ? 'Redlined extracted clause based on standard terms'
        : loadingClauseDocumentComparison || clauseDocumentComparison
        ? 'Compare to preferred language (full contract)'
        : '';

    return (
      <Modal
        dialogClassName="extracted-data-modal"
        show={showExtractData}
        onHide={() => this.setState({ showExtractData: false, clauseDocumentComparison: null, clauseRedlines: null })}
      >
        <Modal.Header closeButton>
          <span className="headline">{title}</span>
        </Modal.Header>
        <Modal.Body>
          {loading && <Loader />}
          {content && <MarkdownRenderer markdown={content} />}
        </Modal.Body>
        <Modal.Footer></Modal.Footer>
      </Modal>
    );
  }

  render() {
    const { deal, container, show, onHide, target, title, user } = this.props;
    const {
      tab,
      selectedClause,
      showIssues,
      showVariableIssues,
      loadingClauseExtraction,
      manuallyEditClauseExtraction,
      editingClause,
      hideNonScoring,
      showQuestions,
      loadingClauseRedlining,
      loadingClauseDocumentComparison,
    } = this.state;
    const { lensChecks, extracted, documentAI, hasLenses, isProcessingAI } = deal;
    const analyzedOn = extracted ? new Date(parseInt(extracted.analyzedOn)) : null;
    const formattedDate = analyzedOn
      ? `${DateFormatter.ymd(analyzedOn, '-')} @ ${DateFormatter.time(analyzedOn)}`
      : null;

    const variablesForReview = extracted?.variables ? _.filter(extracted.variables, { needsReview: true }) : [];

    const terms = showVariableIssues && variablesForReview.length > 0 ? variablesForReview : extracted?.variables;

    const hasAdvancedLenses = _.find(lensChecks?.checks, (check) => check.lens.type === 'advanced');

    return (
      <DealPanel id={'lens'} onHide={onHide} show={show} target={target} title={title} container={container}>
        {this.renderComparisonModal()}
        <div className="filter-bar review-tabs">
          <ButtonGroup className="tabs">
            {PDF_REVIEW_TABS.map((tabDef) => {
              if (tabDef.active(deal, user))
                return (
                  <Button
                    dmpStyle="link"
                    active={tabDef.key === tab}
                    onClick={() => this.setState({ tab: tabDef.key, selectedClause: null })}
                    data-cy={`${tabDef.key}-tab`}
                    disabled={isProcessingAI}
                    key={tabDef.key}
                  >
                    {tabDef.key === 'terms' && variablesForReview.length > 0 && <div className="needsReview" />}
                    {tabDef.title}
                  </Button>
                );
            })}
          </ButtonGroup>
        </div>
        {isProcessingAI && (
          <div className="panel-scroll" data-cy="pdf-review-panel">
            <DealPanelItem borderBottom>
              <div className="analysis-loading" data-cy="analysis-loading">
                <Loader /> AI analysis is in progress, please wait...
              </div>
            </DealPanelItem>
          </div>
        )}
        {!isProcessingAI && (
          <div className="panel-scroll" data-cy="pdf-review-panel">
            {tab === 'summary' && !selectedClause && (
              <>
                {extracted && extracted.error && (
                  <div className="panel-scroll">
                    <DealPanelItem borderBottom>
                      <div className="analysis-loading" data-cy="analysis-loading">
                        <Alert dmpStyle="danger">{extracted.error}</Alert>
                      </div>
                    </DealPanelItem>
                  </div>
                )}
                {extracted && !extracted.error && (
                  <DealPanelItem borderBottom>
                    <>
                      <div className="summary-header">AI analysis</div>

                      <div className="summary-block">
                        Date analyzed
                        <span className="summary-sub-block">{formattedDate}</span>
                      </div>

                      <div className="summary-block">
                        Pages in document
                        <span className="summary-sub-block">{extracted.pages || deal.currentOCR.pages}</span>
                      </div>

                      <div className="summary-block">
                        Variable(s) needing review
                        <span className="summary-sub-block">{variablesForReview.length}</span>
                      </div>

                      {lensChecks && (
                        <div className="summary-block">
                          Risk score
                          <span className="summary-sub-block">{`+${lensChecks.totalRisk}`}</span>
                        </div>
                      )}
                    </>
                  </DealPanelItem>
                )}
                <DealPanelItem borderBottom>
                  <>
                    <div className="summary-header">Services used</div>

                    {_.map(DOCUMENT_ANALYSIS_OPTIONS, (option, idx) => {
                      if (option.test(deal)) {
                        return (
                          <div
                            className={cx('analysis-option-container', { isDefault: option.isDefault })}
                            key={idx}
                            data-cy="analysis-option-container"
                          >
                            <Icon name="ai" className={cx('icon-ai', { isDefault: option.isDefault })} />
                            <div className="analysis-summary" data-cy="analysis-summary">
                              <span className="summary-title">{option.title}</span>
                              <div className="summary-subtext">{option.subtext}</div>
                            </div>
                            <div className="spacer" />
                            <div className={cx('active', { isDefault: option.isDefault })} data-cy="active">
                              Active
                            </div>
                          </div>
                        );
                      }
                    })}
                  </>
                </DealPanelItem>
              </>
            )}
            {tab === 'terms' && !selectedClause && documentAI && extracted && (
              <>
                <DealPanelItem borderBottom>
                  <div className="terms-header-container">
                    <div className="terms-count">{`Extracted (${_.size(extracted.variables)})`}</div>
                    {variablesForReview.length > 0 && (
                      <div className="terms-filter">
                        <Switch
                          id="terms-filter-switch"
                          checked={showVariableIssues}
                          onChange={() => this.setState({ showVariableIssues: !showVariableIssues })}
                          className="terms-filter-switch"
                          size="small"
                        >
                          {`Needs review (${variablesForReview.length})`}
                        </Switch>
                      </div>
                    )}
                  </div>
                </DealPanelItem>
                {_.map(terms, (variable, idx) => this.renderDefinition(variable, idx))}
              </>
            )}
            {tab === 'clauses' && !selectedClause && documentAI && (
              <>
                <DealPanelItem borderBottom>
                  <div className="clause-issue-switch-container">
                    <div className="issue-switch-title">{`Extracted (${_.size(extracted.sections)})`}</div>
                    {hasLenses && this.showFailedSwitch && (
                      <Switch
                        id="show-clause-issues"
                        checked={showIssues}
                        onChange={() => {
                          this.setState({ showIssues: !showIssues });
                        }}
                        className="show-clause-issues"
                        size="small"
                      >
                        {`Show failed vitals`}
                      </Switch>
                    )}
                  </div>
                </DealPanelItem>
                {_.map(extracted.sections, (clause, idx) => this.renderClauses(clause, idx))}
              </>
            )}

            {tab === 'lenses' && lensChecks && !selectedClause && (
              <>
                <DealPanelItem borderBottom>
                  <Switch
                    id="toggle-non-scoring"
                    checked={!hideNonScoring}
                    size="small"
                    onChange={() => this.setState({ hideNonScoring: !hideNonScoring })}
                  >
                    Show zero scores
                  </Switch>
                  {!!hasAdvancedLenses && (
                    <Switch
                      id="toggle-show-questions"
                      checked={showQuestions}
                      size="small"
                      onChange={() => this.setState({ showQuestions: !showQuestions })}
                    >
                      Show advanced lens questions
                    </Switch>
                  )}
                </DealPanelItem>
                <DealPanelItem borderBottom>
                  <LensCheck
                    lensChecks={lensChecks}
                    hideNonScoring={hideNonScoring}
                    showQuestions={showQuestions}
                    deal={deal}
                  />
                </DealPanelItem>
              </>
            )}

            {selectedClause && (
              <>
                <DealPanelItem borderBottom>
                  <div className="clause-comparison-header">
                    <ButtonIcon
                      icon="chevronLeft"
                      onClick={() => this.setState({ selectedClause: null })}
                      data-cy="btn-bundle"
                      size="default"
                    />
                    <span className="compare-clause-title">Back</span>
                  </div>
                </DealPanelItem>
                <DealPanelItem borderBottom>
                  <div className="clause-display" data-cy="clause-display">
                    <div className="clause-display-container">
                      <div className="clause-title">Extracted clause</div>
                      <div className="action-container">
                        <div className="left">
                          {user.isSuper && !loadingClauseRedlining && (
                            <TooltipButton tip="Generate redlines" placement="left">
                              <ButtonIcon
                                data-cy="undo"
                                icon="redlining"
                                className="undo"
                                size="default"
                                disabled={manuallyEditClauseExtraction || loadingClauseDocumentComparison}
                                onClick={this.runRedlining}
                              />
                            </TooltipButton>
                          )}
                        </div>
                        <div className="left">
                          {user.isSuper && !loadingClauseDocumentComparison && (
                            <TooltipButton tip="Compare to preferred language (full contract)" placement="left">
                              <ButtonIcon
                                data-cy="undo"
                                icon="document"
                                className="undo"
                                size="default"
                                disabled={manuallyEditClauseExtraction || loadingClauseRedlining}
                                onClick={this.runPreferedLanguageCheck}
                              />
                            </TooltipButton>
                          )}
                        </div>
                        <div className="left">
                          {!loadingClauseExtraction && (
                            <TooltipButton tip="Re-run clause extraction" placement="left">
                              <ButtonIcon
                                data-cy="undo"
                                icon="undo"
                                className="undo"
                                size="default"
                                disabled={manuallyEditClauseExtraction}
                                onClick={this.rerunClauseExtraction}
                              />
                            </TooltipButton>
                          )}
                          {loadingClauseExtraction && (
                            <TooltipButton tip="Re-running clause extraction" placement="left">
                              <span>
                                <Loader size="default" />
                              </span>
                            </TooltipButton>
                          )}
                        </div>
                        <div className="right">
                          {!manuallyEditClauseExtraction && (
                            <TooltipButton tip="Manually edit clause extraction" placement="left">
                              <ButtonIcon
                                data-cy="draft"
                                icon="edit"
                                className="draft"
                                size="default"
                                disabled={loadingClauseExtraction}
                                onClick={() =>
                                  this.setState({
                                    manuallyEditClauseExtraction: true,
                                    editingClause: extracted.sections[selectedClause.name].extractedClause.trim(),
                                  })
                                }
                              />
                            </TooltipButton>
                          )}
                          {manuallyEditClauseExtraction && (
                            <TooltipButton tip="Cancel edits" placement="left">
                              <ButtonIcon
                                data-cy="close"
                                icon="close"
                                className="close"
                                size="default"
                                onClick={() =>
                                  this.setState({ manuallyEditClauseExtraction: false, editingClause: null })
                                }
                              />
                            </TooltipButton>
                          )}
                        </div>
                      </div>
                    </div>
                    <div
                      className={cx('clause-text-container', { failedCheck: selectedClause.failedCheck })}
                      data-cy="clause-text-container"
                    >
                      {!manuallyEditClauseExtraction && (
                        <div className="clause-text">{extracted.sections[selectedClause.name].extractedClause}</div>
                      )}
                      {manuallyEditClauseExtraction && (
                        <>
                          <FormControl
                            componentClass="textarea"
                            value={editingClause}
                            onChange={(e) => this.setState({ editingClause: e.target.value })}
                          />
                          <div className="edit-actions">
                            <Button
                              className="save-manual-edit"
                              onClick={this.saveManualUpdates}
                              dmpStyle="link"
                              size="small"
                            >
                              Save
                            </Button>
                            <Button
                              className="save-manual-edit"
                              onClick={() =>
                                this.setState({ manuallyEditClauseExtraction: false, editingClause: null })
                              }
                              dmpStyle="link"
                              size="small"
                            >
                              Cancel
                            </Button>
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                </DealPanelItem>
              </>
            )}
          </div>
        )}
      </DealPanel>
    );
  }
}
